//开发环境
const development = {
    wsHost: ['csapi.xdev.stream', 'csapi.hfxg.xyz'],
    uploadHost: 'https://csapi.xdev.stream', //上传资源路径
    viewUploadHost: 'https://sssacc.wwc09.com', //获取资源路径
}

//生产环境
const production = {
    wsHost: ['csapi.ertw.xyz', 'csapi.weletter02.com'],
    uploadHost: 'https://csapi.ertw.xyz', //上传资源路径
    viewUploadHost: 'https://images2acc.wwc09.com', //获取资源路径
}

let env = development;
if (process.env.NODE_ENV === 'production') {
    env = production
}

export default env;